<script setup>
import { trackUmamiEvent } from '@jaseeey/vue-umami-plugin';
import ReadyToBecomeAnXprtBanner from 'components/banner/ReadyToBecomeAnXprtBanner.vue';
import TimeToUpgradeYourPaperCVBanner from 'components/banner/TimeToUpgradeYourPaperCVBanner.vue';
import ContributionAnimationWallpaper from 'components/ContributionAnimationWallpaper.vue';
import FAQItem from 'components/faq-item/FAQItem.vue';
import HowToCreateProfileFAQItem from 'components/faq-item/HowToCreateProfileFAQItem.vue';
import WhatIsXprtFAQItem from 'components/faq-item/WhatIsXprtFAQItem.vue';
import WhoCanUseXprtFAQItem from 'components/faq-item/WhoCanUseXprtFAQItem.vue';
import XprtDifferenceFAQItem from 'components/faq-item/XprtDifferenceFAQItem.vue';
import XprtPrivacyProtectionFAQItem from 'components/faq-item/XprtPrivacyProtectionFAQItem.vue';
import LandingFeatureItem from 'components/feature-item/LandingFeatureItem.vue';
import { useMeta, useQuasar } from 'quasar';
import AppRoute from 'src/router/app-route.js';
import { Route } from 'src/router/routes.js';
import { ref } from 'vue';

const quasar = useQuasar();

const sectionZeroElement = ref(null);

function scrollToFeatureSection() {
    const featuresSection = sectionZeroElement.value;
    if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
}

useMeta({
    title: 'Xprt: Connect with Top Software Engineers & Discover Tech Job Opportunities',
    titleTemplate: title => title, // Omit 'Xprt' suffix on landing page only.
    meta: {
        description: {
            name: 'description',
            content: 'Xprt is the premier platform for software engineers to showcase their expertise, connect with peers, and discover tech job opportunities. Build your digital '
                + 'CV, control your privacy, and engage with the engineering community.'
        },
        ogTitle: {
            property: 'og:title',
            content: 'Xprt: Connect with Top Software Engineers & Discover Tech Job Opportunities'
        },
        ogDescription: {
            property: 'og:description',
            content: 'Join Xprt to elevate your engineering career with a dynamic digital CV, connect with industry leaders, and find exclusive tech job opportunities. Your next '
                + 'professional breakthrough starts here.'
        },
        ogSiteName: {
            property: 'og:site_name',
            content: 'xprt.dev'
        },
        ogType: {
            property: 'og:type',
            content: 'website'
        },
        ogImage: {
            property: 'og:image',
            content: 'https://xprt.syd1.cdn.digitaloceanspaces.com/pub/xprt-icon.png'
        }
    }
});
</script>

<template>
    <div id="landing-page" class="flex column">
        <div class="section__hero">
            <ContributionAnimationWallpaper/>
            <div class="section__hero-content container container-md text-center q-px-md">
                <h1 class="heading__primary text-display1 text-weight-medium">
                    <span class="text-weight-bold">Engineers</span>, tired of pitching your skills instead of proving them?
                </h1>
                <h2 class="heading__secondary text-h4 text-weight-regular font-body">
                    Let Xprt help you demonstrate your technical expertise.
                </h2>
                <q-btn
                    @click="trackUmamiEvent('click_build_profile_button', { component: 'LandingPage' })"
                    :to="AppRoute.build(Route.Login)"
                    color="primary"
                    label="Start building my profile"
                    size="xl"
                    unelevated
                />
                <div class="section__hero-next">
                    <q-no-ssr>
                        <q-btn
                            @click="scrollToFeatureSection(); trackUmamiEvent('click_discover_more_button', { component: 'LandingPage' })"
                            :color="quasar.dark.isActive ? 'white': 'secondary'"
                            label="Tell me more"
                            size="xl"
                            outline
                            unelevated
                        />
                    </q-no-ssr>
                </div>
            </div>
        </div>
        <section ref="sectionZeroElement" class="section__zero">
            <div class="container container-md">
                <div class="text-center">
                    <h3 class="text-h1 text-weight-bold x-my">
                        Enter your showcase.
                    </h3>
                    <h6 class="text-h4 text-weight-regular font-body x-my">
                        One that sells, so you <em>don't</em> have to.
                    </h6>
                    <div class="row q-pt-xl q-col-gutter-lg">
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Effortlessly highlight your achievements" icon="sym_s_trophy" icon-size="xl">
                            Your Xprt profile effectively articulates your capabilities. It transforms major projects, professional milestones, and achievements into a compelling
                            narrative that draws the right attention, effortlessly positioning you in the job market.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Showcase your dedication and skills" icon="sym_s_build" icon-size="xl">
                            Link your GitHub and/or GitLab profiles to demonstrate your ongoing commitment. This provides a transparent view of your daily commits and
                            participation, offering potential collaborators and employers insight into your dedication and technical proficiency.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Demonstrate your technical expertise" icon="sym_s_terminal" icon-size="xl">
                            Give direct access to your most impressive repositories. Detailing your contributions to complex projects not only showcases your technical skills but
                            also your ability to innovate and solve real-world problems, making a strong case for your expertise.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Optimise your professional visibility" icon="sym_s_visibility" icon-size="xl">
                            Create unique profile links with basic analytics to monitor your profile’s reach and engagement. This allows you to see how your profile performs across
                            your networks, helping you refine where and how you share it to maximise visibility and impact.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Keep your network informed" icon="sym_s_lightbulb" icon-size="xl">
                            Xprt ensures that your CV is always current, reflecting your latest projects, skills, and professional achievements. This keeps you prepared for new
                            opportunities as they arise, enhancing your professional reach and keeping you relevant at all times.
                        </LandingFeatureItem>
                    </div>
                </div>
            </div>
        </section>
        <section class="section__one">
            <div class="container container-md">
                <div class="text-center">
                    <h3 class="text-h1 text-weight-bold x-my">
                        Control your privacy.
                    </h3>
                    <p class="text-h4 text-weight-regular font-body x-my">
                        Hold the reins to <em>your</em> data and choose who sees what.
                    </p>
                    <div class="row q-pt-xl q-col-gutter-lg">
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Always own your data" icon="sym_s_shield_lock" icon-size="xl">
                            Take charge of how your data is shared and who accesses it. Assert your rights with Xprt, where you keep full ownership of your content, ensuring your
                            professional information remains private and under your control.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Control who can see your data" icon="sym_s_visibility_lock" icon-size="xl">
                            Manage your profile's visibility with Xprt's three privacy settings &mdash; public, authenticated, and invite-only. Tailor your exposure to meet your
                            personal and professional privacy needs effectively.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6 q-mx-auto" title="Password protect your links" icon="sym_s_password" icon-size="xl">
                            Apply password protection to your unique profile links to control access. This feature ensures that your personal and professional information is
                            accessible only to those you permit, safeguarding against unauthorized viewing.
                        </LandingFeatureItem>
                    </div>
                </div>
            </div>
        </section>
        <TimeToUpgradeYourPaperCVBanner/>
        <section class="section__two">
            <div class="container container-md">
                <div class="text-center">
                    <q-badge label="Coming soon" class="text-subtitle1 text-uppercase q-px-sm q-py-xs"/>
                    <h3 class="text-h1 text-weight-bold x-my">
                        Find engineers and opportunities.
                    </h3>
                    <h6 class="text-h4 text-weight-regular font-body x-my">
                        Upgrade your career to the next level.
                    </h6>
                    <div class="row q-mb-xl q-pt-xl q-col-gutter-lg">
                        <LandingFeatureItem class="col-12 col-sm-6" title="For job seekers" icon="sym_s_work" icon-size="xl">
                            In the future, you'll be able to navigate through a variety of job listings specifically tailored for engineers. Xprt will provide a platform where
                            employers will be able to actively seek out engineers who not only demonstrate exceptional technical skills but also embrace innovative thinking &mdash;
                            indispensable qualities in the evolving tech world.
                        </LandingFeatureItem>
                        <LandingFeatureItem class="col-12 col-sm-6" title="For talent scouts" icon="sym_s_search" icon-size="xl">
                            You'll have the opportunity to discover and engage with engineers who are more than just skilled &mdash; those who are visionary and adept at overcoming
                            complex challenges. This upcoming tool will serve as your portal to find professionals ready to drive innovation and excel in projects, adapting to the
                            ever-changing demands of the tech industry.
                        </LandingFeatureItem>
                    </div>
                </div>
                <div class="faq-items q-gutter-y-sm">
                    <FAQItem question="What will the Job Search feature on Xprt offer?">
                        <p>
                            The upcoming Job Search feature will enable job seekers to discover a number of employment opportunities that align with their professional skills and
                            career goals. This feature will include advanced filtering options, enabling users to efficiently locate job openings that are not only relevant but
                            also conducive to their growth.
                        </p>
                        <p>
                            <span class="text-subtitle2">Future key features of Job Search:</span>
                        </p>
                        <ul>
                            <li>
                                <strong>Recruiter and Employer Postings:</strong> Recruiters and employers will be able to post job listings targeting software engineers and
                                related roles, making it easier to discover suitable talent.
                            </li>
                            <li>
                                <strong>Application with Xprt Profile:</strong> Engineers will be able to apply for jobs using their Xprt profile, showcasing their experience,
                                skills, and portfolio comprehensively without the need for a traditional CV.
                            </li>
                            <li>
                                <strong>Customised Job Discovery:</strong> Job seekers will have access to advanced search filters to tailor their job search based on criteria such
                                as skills, experience, and location, aiding them in discovering the most fitting opportunities.
                            </li>
                        </ul>
                    </FAQItem>
                    <FAQItem question="What will the People Search feature on Xprt include?">
                        <p>
                            The upcoming People Search feature will connect a network of engineering professionals globally. It will serve as a tool for enabling engineers to
                            interact for collaboration, team building, or knowledge sharing. People Search will include targeted search functionalities to find and connect with
                            engineers based on specific skills, technologies, and locations.
                        </p>
                        <p>
                            <span class="text-subtitle2">Future key features of People Search:</span>
                        </p>
                        <ul>
                            <li>
                                <strong>Skill and Location-Based Searching:</strong> Users will be able to search for engineers or peers using specific skills, technologies, or
                                location criteria, enabling focused and effective networking.
                            </li>
                            <li>
                                <strong>Project Collaboration:</strong> Engineers will have the chance to find and collaborate with peers who have the necessary expertise for
                                innovative projects or initiatives.
                            </li>
                            <li>
                                <strong>Professional Networking:</strong> The feature will offer a platform for engineers to network, share insights, and engage with the wider
                                engineering community, promoting professional growth and connections.
                            </li>
                        </ul>
                    </FAQItem>
                    <FAQItem question="How will these features assist in finding the right opportunities or talent?">
                        The Job Search feature will use advanced filtering to introduce job seekers with ideal opportunities, while People Search will enable precise identification
                        of engineering talent based on specific criteria. Together, these features will enhance team building and collaboration, ensuring users can find suitable
                        roles or candidates effectively.
                    </FAQItem>
                    <FAQItem question="How can I stay updated on the launch of Job Search and People Search on Xprt?">
                        <ul>
                            <li>
                                <strong>For Engineers:</strong> By creating your profile now and remaining active on Xprt, you'll be among the first to know when these features are
                                available, giving you a head start in accessing a wide range of opportunities and connections.
                            </li>
                            <li>
                                <strong>For Recruiters and Employers:</strong> By registering and creating your account today, you'll be promptly notified upon the feature's
                                launch, providing you with immediate access to Xprt's specialised recruitment features. Your account will receive initial enhancements to ensure you
                                can fully utilise Xprt's networking capabilities from the start.
                            </li>
                        </ul>
                    </FAQItem>
                </div>
            </div>
        </section>
        <section class="section__three">
            <div class="container container-md">
                <div class="text-center q-mb-xl">
                    <h3 class="text-h1 text-weight-bold x-my">
                        FAQs.
                    </h3>
                </div>
                <div class="faq-items q-gutter-y-sm q-pt-lg">
                    <WhatIsXprtFAQItem inverted/>
                    <WhoCanUseXprtFAQItem inverted/>
                    <HowToCreateProfileFAQItem inverted/>
                    <XprtPrivacyProtectionFAQItem inverted/>
                    <XprtDifferenceFAQItem inverted/>
                </div>
            </div>
        </section>
        <ReadyToBecomeAnXprtBanner/>
    </div>
</template>

<style lang="scss" scoped>
#landing-page {
    flex: 1;
}

section {
    padding: 64px 16px 110px;

    &:nth-child(even) {
        background-color: $grey-1;

        .body--dark & {
            background-color: $dark;
        }
    }

    &.section__zero {
        scroll-margin: 62px;
    }
}

.spacer {
    margin: auto 0;
}

.section__hero {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 62px);
    justify-content: center;
    min-height: 850px;
    position: relative;
    width: 100%;

    .body--dark & {
        h1,
        h2 {
            text-shadow: 0 0 40px $black;
        }
    }

    .section__hero-content {
        margin: 50px 0;
        z-index: 1;
    }

    .section__hero-next {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding: 32px 0;
    }
}

.heading__ {
    &primary, &secondary {
        -webkit-user-select: none;
        user-select: none;
    }

    &primary {
        margin-bottom: 46px;
    }

    &secondary {
        margin-bottom: 62px;
    }
}

.faq-items {
    max-width: 1100px;
    margin: 0 auto;
}
</style>
